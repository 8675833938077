<template>
  <section class="settings">
    {{ $router.history }}
    <div class="settings__close" @click="close" v-if="$isMobile">
      <img src="@/assets/icons/clear.svg" alt="Close" />
    </div>
    <h1 class="settings__title page__title">Мои данные</h1>
    <div class="settings-fields fields page-wrapper">
      <div class="settings-content-row fields-row">
        <base-input
          :label="'Имя и фамилия'"
          :id="'settingsName'"
          v-model="data.fio"
        />
      </div>
      <div class="settings-content-row fields-row">
        <base-input
          :label="'Телефон'"
          type="number"
          ref="phone"
          :placeholder="'+7 (___) __-__-___'"
          :maxLength="18"
          :id="'settingsPhone'"
          v-model="data.phone_number"
        />
      </div>
      <div class="settings-content-row">
        <base-input
          :label="'Электронная почта'"
          :id="'settingsEmail'"
          v-model="data.email"
        />
      </div>
    </div>
    <div class="settings-buttons page-buttons _save">
      <div class="settings-buttons-save _save__button">
        <base-button type="filled" @clicked="save"> Сохранить </base-button>
      </div>
    </div>
  </section>
  <base-modal
    :open="openModal"
    :text="'Сохранено'"
    @close="openModal = false"
  />
</template>

<script>
import { mapState } from "vuex";
import { useMeta } from "vue-meta";

export default {
  name: "Settings",
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  computed: {
    ...mapState(["user"]),
  },
  data() {
    return {
      data: {
        fio: "",
        phone_number: "",
        email: "",
      },
      openModal: false,
      prevRoute: null,
    };
  },
  setup() {
    useMeta({ title: "Мои Данные" });
  },
  mounted() {
    for (const [key] of Object.entries(this.data)) {
      this.data[key] = this.user[key] || "";
    }
  },
  methods: {
    save() {
      if (!Object.values(this.data).some((i) => !i)) {
        this.$store.dispatch("loading", true);
        this.$api
          .patch(`${this.$api.defaults.API}/user/${this.user.id}`, this.data)
          .then((res) => {
            const user = res.data;
            user.id = this.user.id;
            user.token = this.user.token;
            localStorage.setItem("user", JSON.stringify(user));
            this.$store.dispatch("setUser", user);
            this.$store.dispatch("loading", false);
            this.openModal = true;
          })
          .catch((err) => {
            new Error(err);
            this.$store.dispatch("loading", false);
          });
      }
    },
    close() {
      this.$router.push({ name: this.prevRoute.name || "index" });
    },
  },
};
</script>

<style lang="scss" scoped>
.settings {
  &-content {
    margin-bottom: 50px;
    &__title {
      font-family: $regular;
      font-size: 16px;
      font-weight: 400;
    }
  }
}
@media (max-width: 992px) {
  .settings {
    width: 100%;
    min-height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: $white;
    &__close {
      display: block;
      position: absolute;
      top: 12px;
      right: 12px;
      cursor: pointer;
    }
    &__title {
      margin-top: 19px;
    }
  }
}
</style>
